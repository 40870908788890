import React, { useState, ChangeEvent, FormEvent } from "react";
import { Box, Heading, Text, FormControl, FormLabel, Input, Textarea, Button, VStack, Checkbox, CheckboxGroup, Stack, Alert, AlertIcon, Grid, GridItem, Image, Link } from "@chakra-ui/react";
import GreenPaw from "../../assets/green-paw.png";
import OrangePaw from "../../assets/orange-paw.png";
import { lightGreen, primaryGreen, yellow } from "../../constants";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

interface FormData {
  name: string;
  address: string;
  email: string;
  telephone: string;
  roles: string[];
  whyVolunteer: string;
  skills: string;
  availability: string;
  startDate: string;
}

export const VolunteeringForm: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    address: "",
    email: "",
    telephone: "",
    roles: [],
    whyVolunteer: "",
    skills: "",
    availability: "",
    startDate: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (roles: string[]) => {
    setFormData({
      ...formData,
      roles,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Create the email body with all the form data
    const emailSubject = `Volunteer Application from ${formData.name}`;
    const emailBody = `
Name: ${formData.name}
Address: ${formData.address}
Email: ${formData.email}
Telephone Number: ${formData.telephone}

Roles interested in: ${formData.roles.join(", ")}

Why I would like to volunteer at Muttley's Doggy Daycare:
${formData.whyVolunteer}

Skills/experience I would bring to the role:
${formData.skills}

Days/hours I would like to volunteer: ${formData.availability}

When I would be able to start: ${formData.startDate}
    `;

    // Encode the subject and body for use in the mailto URL
    const encodedSubject = encodeURIComponent(emailSubject);
    const encodedBody = encodeURIComponent(emailBody);

    // Create the mailto URL
    const mailtoUrl = `mailto:info@muttleysdoggydaycare.com?subject=${encodedSubject}&body=${encodedBody}`;

    // Open the mail client
    window.location.href = mailtoUrl;

    // Show the success message
    setFormSubmitted(true);
  };
  return (
    <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} position="relative" overflow="hidden">
      <Box maxW="7xl" mx="auto" px={6} mb={{ base: 10, md: 10, lg: 15, xl: 300 }}>
        <Grid templateColumns={{ base: "1fr", md: "1fr" }} gap={8}>
          <GridItem>
            <VStack align="flex-start" spacing={8} width="100%">
              <Heading as="h2" size="xl" color="white" fontFamily="'Baloo 2', sans-serif">
                How to apply
              </Heading>
              <Text color="white" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Review the role descriptions for our volunteer positions, then fill out the form below stating which role you're interested in. We'll be in touch about next steps.
              </Text>

              {formSubmitted ? (
                <Alert status="success" borderRadius="md" p={6} width="100%">
                  <AlertIcon />
                  <Text fontFamily="'Baloo 2', sans-serif">Thank you for submitting your application. One of the Muttley's team will be in touch shortly.</Text>
                </Alert>
              ) : (
                <Box as="form" onSubmit={handleSubmit} width="100%" bg="gray.50" p={8} borderRadius="xl">
                  <VStack spacing={6} align="flex-start">
                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Name</FormLabel>
                      <Input name="name" value={formData.name} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Address</FormLabel>
                      <Input name="address" value={formData.address} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Email</FormLabel>
                      <Input name="email" type="email" value={formData.email} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Telephone Number</FormLabel>
                      <Input name="telephone" value={formData.telephone} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired={formData.roles.length === 0}>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Which role would you like to apply for:</FormLabel>
                      <CheckboxGroup colorScheme="green" value={formData.roles} onChange={handleCheckboxChange}>
                        <Stack spacing={3} direction={{ base: "column", md: "column" }}>
                          <Checkbox value="animal-care" fontFamily="'Baloo 2', sans-serif">
                            Animal Care Volunteer
                          </Checkbox>
                          <Checkbox value="doggy-daycare" fontFamily="'Baloo 2', sans-serif">
                            Doggy Daycare Volunteer
                          </Checkbox>
                          <Checkbox value="landscaping" fontFamily="'Baloo 2', sans-serif">
                            Landscaping Volunteer
                          </Checkbox>
                          <Checkbox value="maintenance" fontFamily="'Baloo 2', sans-serif">
                            Maintenance Volunteer
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">Why would you like to volunteer at Muttley's Doggy Daycare?</FormLabel>
                      <Textarea name="whyVolunteer" value={formData.whyVolunteer} onChange={handleInputChange} bg="white" rows={4} fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">What skills/experience would you bring to the role?</FormLabel>
                      <Textarea name="skills" value={formData.skills} onChange={handleInputChange} bg="white" rows={4} fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">What days/hours would you like to volunteer at Muttley's?</FormLabel>
                      <Input name="availability" value={formData.availability} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontFamily="'Baloo 2', sans-serif">When would you be able to start?</FormLabel>
                      <Input name="startDate" type="date" value={formData.startDate} onChange={handleInputChange} bg="white" fontFamily="'Baloo 2', sans-serif" />
                    </FormControl>

                    <Text fontSize="sm" color="gray.600" fontFamily="'Baloo 2', sans-serif">
                      Once submitted, your email client will open automatically, allowing you to send the form to{" "}
                      <Link href="mailto:info@muttleysdoggydaycare.com" color={primaryGreen}>
                        info@muttleysdoggydaycare.com
                      </Link>
                      .
                    </Text>
                    <Button type="submit" colorScheme="green" bg={primaryGreen} size="lg" fontFamily="'Baloo 2', sans-serif" px={8}>
                      Submit
                    </Button>
                  </VStack>
                </Box>
              )}
            </VStack>
          </GridItem>
        </Grid>
        <Image src={GreenPaw} alt="Green Paw" position="absolute" top="0" right="0" w="10%" zIndex={1} />
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
