import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import { FaPaw } from "react-icons/fa";

import GreenPaw from "../../assets/green-paw.png";
import OrangePaw from "../../assets/orange-paw.png";
import PackLeader from "../../assets/pack-leader.png";
import { lightGreen, primaryGreen } from "../../constants";

export const VolunteeringRequirements = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} pos="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 0.7fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Requirements
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                To join Muttley's Crew, you must:
              </Heading>
              <Text color="black" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                • Love being around dogs and are comfortable with them
                <br />
                • Happy to help with cleaning, maintenance, or landscaping
                <br />• Be happy to lend a paw with Doggy Day Care duties such as throwing balls, supervising nap time, or even running doggy discos!
              </Text>
              <Text color="black" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Join our team and become a part of our mission to create a joyful and welcoming environment for both animals and visitors!
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image height="auto" borderRadius="lg" aspectRatio={1} src={PackLeader} alt="Volunteer requirements" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={GreenPaw} alt="Green Paw" position="absolute" top="0" right="0" w="10%" zIndex={1} />
        <Image src={OrangePaw} alt="Orange Paw" position="absolute" bottom="-10" left="0" w="10%" zIndex={1} />
      </Box>
    </Box>
  );
};
