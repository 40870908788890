import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import "../main.css";

import { VolunteeringIntro } from "../components/volunteering/VolunteeringIntro";
import { VolunteeringRequirements } from "../components/volunteering/VolunteeringRequirements";
import { VolunteeringBenefits } from "../components/volunteering/VolunteeringBenefits";
import { VolunteeringVacancies } from "../components/volunteering/VolunteeringVacancies";
import { VolunteeringForm } from "../components/volunteering/VolunteeringForm";
import { facebookLink, instagramLink, lightGreen } from "../constants";

export const VolunteeringPage = () => {
  return (
    <Box>
      <Helmet>
        <title>Volunteering at Muttley's - Join Muttley's Crew!</title>
        <meta name="description" content="Join Muttley's Crew, our innovative volunteer programme offering rewarding opportunities to make a positive impact while being surrounded by animals and nature." />
        <meta name="keywords" content="doggy daycare volunteers, dog care volunteering, animal care volunteer, Muttley's Crew, Christchurch volunteer" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VolunteerOpportunity",
            name: "Muttley's Crew Volunteer Programme",
            url: "https://muttleysdoggydaycare.com/volunteering",
            description: "Join Muttley's Crew, our innovative volunteer programme offering rewarding opportunities to make a positive impact while being surrounded by animals and nature.",
            organizer: {
              "@type": "Organization",
              name: "Muttley's Doggy Daycare",
              url: "https://muttleysdoggydaycare.com",
              sameAs: [facebookLink, instagramLink],
            },
            location: {
              "@type": "PostalAddress",
              streetAddress: "Home Farm Hurn Christchurch",
              addressLocality: "Christchurch",
              postalCode: "BH23 6AE",
              addressCountry: "UK",
            },
            skills: ["Dog handling", "Animal care", "Landscaping", "Maintenance"],
            responsibilities: ["Animal care", "Doggy daycare assistance", "Landscaping", "Site maintenance"],
            benefits: ["Flexible hours", "Training opportunities", "Time with leading dog behaviourists", "Puppy group participation"],
          })}
        </script>
      </Helmet>

      <VolunteeringIntro />
      <VolunteeringRequirements />
      <VolunteeringBenefits />
      <VolunteeringVacancies />
      <VolunteeringForm />
    </Box>
  );
};
