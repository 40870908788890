import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AboutUsPage } from "./pages/About";
import { FAQPage } from "./pages/FAQPage";
import { Services } from "./pages/Services";
import { ContactUs } from "./pages/ContactUs";

import { Hero } from "./pages/Hero";
import { Navbar } from "./components/navbar/Navbar";

import "./main.css";

import { LandingPage } from "./pages/LandingPage";
import { Behavioural } from "./pages/Behavioural";
import { Puppies } from "./pages/Puppies";
import { Footer } from "./components/footer/Footer";
import { DaycarePage } from "./pages/Daycare";
import { ScrollToTop } from "./components/shared/ScrollToTop";
import { TermsAndConditionsPage } from "./pages/TermsAndConditionsPage";
import { VolunteeringPage } from "./pages/Volunteering";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <Routes>
        <Route path="/" Component={LandingPage} />
        <Route path="/about" Component={AboutUsPage} />
        <Route path="/faqs" Component={FAQPage} />
        <Route path="/services" Component={Services} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/volunteering" element={<VolunteeringPage />} />
        <Route path="/daycare" element={<DaycarePage />} />
        <Route path="/puppies" element={<Puppies />} />
        <Route path="/behavioural" element={<Behavioural />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
