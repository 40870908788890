import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import { FaPaw } from "react-icons/fa";

import LightGreenWavyBottom from "../../assets/light-green-wavy-bottom.png";
import Paw from "../../assets/paw.png";
import WhoWeAre from "../../assets/about-who-we-are.png"; // Using existing image
import { lightGreen, yellow, primaryGreen } from "../../constants";

export const VolunteeringIntro = () => {
  return (
    <Box bgColor="white">
      <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} pos="relative">
        <Box maxW="7xl" mx="auto" px={6}>
          <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
            <GridItem>
              <VStack align="flex-start" justify="center" spacing={6}>
                <Heading as="h2" size="lg" color={yellow} fontFamily="'Baloo 2', sans-serif">
                  Who are we?
                </Heading>
                <Heading as="h2" size="xl" color="white" fontFamily="'Baloo 2', sans-serif">
                  Volunteering at Muttley's - Join Muttley's Crew!
                </Heading>
                <Text color="white" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                  Muttley's Crew is our innovative volunteer programme, offering rewarding opportunities to make a positive impact while being surrounded by animals and nature.
                  <br />
                  <br />
                  Whether you're passionate about animal care, enjoy hands-on work, or have a flair for landscaping, there's a role for you! We offer a variety of different positions including animal care volunteers, looking after our dogs, chickens, and horses, ensuring they're happy and well-cared for. Maintenance volunteers play a vital role in keeping our site safe, tidy, and fresh, while landscaping volunteers contribute to creating beautiful, spacious grounds where the dogs can run and
                  play.
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <Center h="100%">
                <Image width="100%" height="auto" borderRadius="lg" aspectRatio={1} src={WhoWeAre} alt="Dog volunteers" />
              </Center>
            </GridItem>
          </Grid>
          <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
        </Box>
      </Box>
      <Image src={LightGreenWavyBottom} alt="Wavy Bottom Decoration" style={{ width: "120%" }} />
    </Box>
  );
};
