import { Button, Drawer, DrawerBody, DrawerContent, DrawerProps, Stack, DrawerCloseButton } from "@chakra-ui/react";
import { orange, primaryGreen } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";

export const MobileDrawer = (props: Omit<DrawerProps, "children">) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onButtonClick = (item: string) => {
    if (item === "Home") {
      navigate("/");
      return;
    }
    navigate(item.toLowerCase());
  };

  const isSelected = (item: string) => {
    switch (item) {
      case "Home":
        return pathname === "/";
      case "About":
        return pathname === "/about";
      case "Services":
        return pathname === "/services";
      case "FAQs":
        return pathname === "/faqs";
      case "Contact":
        return pathname === "/contact";
      case "Volunteering":
        return pathname === "/volunteering";
      default:
        return false;
    }
  };

  return (
    <Drawer placement="top" {...props}>
      <DrawerContent bg={primaryGreen}>
        {/* Add the DrawerCloseButton here */}
        <DrawerCloseButton color="white" zIndex={1000} />
        <DrawerBody bg={primaryGreen} mt="16">
          <Stack spacing="6" align="stretch">
            {["Home", "About", "Services", "FAQs", "Volunteering", "Contact"].map((item) => (
              <Button key={item} size="lg" variant="text" colorScheme="gray" color={isSelected(item) ? orange : "white"} onClick={() => onButtonClick(item)}>
                {item}
              </Button>
            ))}
            <Button
              bg={orange}
              color="white"
              onClick={() => {
                navigate("contact");
              }}
            >
              Contact Us
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
