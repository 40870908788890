import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";

import GreenPaw from "../../assets/green-paw.png";
import OrangePaw from "../../assets/orange-paw.png";
import Facilities from "../../assets/woods.png"; // Using existing image
import { lightGreen, primaryGreen } from "../../constants";

export const VolunteeringBenefits = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} pos="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <Center h="100%">
              <Image width="100%" height="auto" borderRadius="lg" aspectRatio={1} src={Facilities} alt="Volunteer benefits" />
            </Center>
          </GridItem>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                What's in it for you?
              </Heading>
              <Heading as="h2" size="xl" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                In return, we offer:
              </Heading>
              <Text color="black" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                • Flexible hours to suit your schedule (2 hours a week or 2 days a month—whatever works for you!)
                <br />
                • Training opportunities in canine first aid
                <br />
                • Time spent with one of the area's leading dog behaviourists
                <br />
                • Opportunities to be associated with a leading dog charity
                <br />
                • The chance to shadow, monitor, and learn
                <br />
                • The option to take part in puppy groups (so much cuteness!)
                <br />
                • Gain valuable work experience in a professional but friendly environment
                <br />• And, of course, plenty of dog cuddles!
              </Text>
            </VStack>
          </GridItem>
        </Grid>
        <Image src={GreenPaw} alt="Green Paw" position="absolute" top="0" right="0" w="10%" zIndex={9} />
        <Image src={OrangePaw} alt="Orange Paw" position="absolute" bottom="0" left="0" w="10%" zIndex={9} />
      </Box>
    </Box>
  );
};
