import React from "react";
import { Box, Heading, Text, Grid, GridItem, Link, VStack, Flex, Image } from "@chakra-ui/react";
import { lightGreen, primaryGreen, yellow } from "../../constants";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

interface RoleCardProps {
  title: string;
  description: string;
  pdfLink: string;
}

const RoleCard: React.FC<RoleCardProps> = ({ title, description, pdfLink }) => (
  <Box bg="white" p={6} borderRadius="xl" height="100%" boxShadow="md">
    <VStack align="start" spacing={4}>
      <Heading as="h3" size="md" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
        <Link href={pdfLink} download fontFamily="'Baloo 2', sans-serif">
          {title}
        </Link>
      </Heading>
      <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
        {description}
      </Text>
    </VStack>
  </Box>
);

export const VolunteeringVacancies: React.FC = () => {
  const roles = [
    {
      title: "Animal Care Volunteer",
      description: "Our on site horses, chickens and dogs need some special attention. Help with their daily care and maintenance with mucking out stables, feeding and grooming.",
      pdfLink: require("../../assets/pdf/animal-care-volunteer.pdf"),
    },
    {
      title: "Landscaping Volunteer",
      description: "Assist with the upkeep and beautification of our work site through gardening and landscaping",
      pdfLink: require("../../assets/pdf/landscaping-volunteer.pdf"),
    },
    {
      title: "Maintenance Volunteer",
      description: "Help out with various DIY and maintenance tasks that contribute to the overall upkeep of our doggy daycare.",
      pdfLink: require("../../assets/pdf/maintenance-volunteer.pdf"),
    },
    {
      title: "Doggy Daycare Volunteer",
      description: "Get stuck into doggy daycare and help the team with supervising playtime and keeping dog spaces clean and tidy.",
      pdfLink: require("../../assets/pdf/doggy-daycare-volunteer.pdf"),
    },
  ];

  return (
    <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} position="relative" overflow="hidden">
      <Box maxW="7xl" mx="auto" px={6} mb={{ base: 10, md: 10, lg: 15, xl: 100 }}>
        <VStack spacing={8} mb={12}>
          <Heading as="h2" size="xl" color="white" textAlign="center" fontFamily="'Baloo 2', sans-serif">
            Explore our current Volunteer vacancies
          </Heading>

          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "repeat(4, 1fr)" }} gap={6} width="100%">
            {roles.map((role, index) => (
              <GridItem key={index}>
                <RoleCard title={role.title} description={role.description} pdfLink={role.pdfLink} />
              </GridItem>
            ))}
          </Grid>
        </VStack>
      </Box>
    </Box>
  );
};
